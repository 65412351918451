<script setup>
  import { RouterLink, RouterView } from 'vue-router'
</script>

<template>
  <div id="app">
    <container data-theme="light" class="mainContainer">
      <div v-if="$store.state.isOfficeAddin" class="flex flex-col" style="height: 100vh;">
        <div class="flex-grow overflow-auto">
          <div class="absolute top-0 right-3">
            <div v-if="user" class="dropdown dropdown-end z-1000">
                <div tabindex="0" role="button" class="btn btn-ghost btn-circle">
                  <div class="w-10 h-10 flex items-center justify-center rounded-full">
                    <i class="bi bi-person-circle text-2xl"></i>
                  </div>
                </div>
                <ul tabindex="0" class="menu menu-sm dropdown-content z-[1] p-2 shadow bg-base-100 rounded-box w-52">
                <!--<li><RouterLink @click="closeDropdownOnClick()" class="nav-link" to="/profile">Profile</RouterLink></li>-->
                <li><RouterLink @click="closeDropdownOnClick()" class="nav-link" to="/AIBatchProcessExcelAddinHelp">Help</RouterLink></li>
                <li><RouterLink @click="closeDropdownOnClick()" class="nav-link" to="/purchaseCredits">Recharge credits</RouterLink></li>
                <!--<li><a @click.prevent="openWebVersion">Try the web version!</a></li>-->
                <li @click="closeDropdownOnClick()"><RouterLink class="nav-link" to="/AIBatchProcessExcelAddinManageAccount">Manage Account</RouterLink></li>
                <li><a @click.prevent="signout">Signout</a></li>
                </ul>
            </div>
          </div>
          <div data-theme="light">
            <RouterView />
          </div>
        </div>
        <footer class="footer items-center p-1 bg-neutral text-neutral-content mt-auto flex justify-between">
          <p v-html="currentPlanStatus"></p>
          <a href="#" @click.prevent="openAifficientoolsLink" class="font-bold">AIfficientools.com</a>
        </footer>
      </div>
      <div v-else-if="$route.name">
        <!-- Navbar -->
        <div class="navbar bg-base-100 text-white fixed z-50" ref="navbar" id="mainNavbar" data-theme="business">
              <div class="flex-1">
                <label v-if="$route.meta.showNavSidebar" for="my-drawer" class="btn btn-sm btn-neutral lg:hidden -p-2 mr-2" data-theme="light">
                  <i class="bi bi-list text-xl"></i>
                </label>
                <RouterLink class="text-xl font-semibold" to="/">AIfficientools</RouterLink>
              </div>
              <div class="flex-none">
                <RouterLink class="me-10" to="/blog">Blog</RouterLink>
                <span v-if="user" class="pr-1">{{ userDisplayName }}</span>
                <div v-if="user" class="dropdown dropdown-end">
                    <div tabindex="0" role="button" class="btn btn-ghost btn-circle avatar">
                      <div class="w-10 rounded-full">
                        <i class="bi bi-person-circle text-3xl"></i>
                      </div>
                    </div>
                    <ul tabindex="0" class="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
                    <!--<li>
                        <a class="justify-between">
                            Profile
                            <!--<span class="badge">New</span>
                        </a>
                    </li>-->
                    <li><RouterLink @click="closeDropdownOnClick()" class="nav-link" to="/profile">Profile</RouterLink></li>                   
                    <li><RouterLink @click="closeDropdownOnClick()" class="nav-link" to="/purchaseCredits">Recharge credits</RouterLink></li>
                    <li><a @click.prevent="signout">Signout</a></li>
                    </ul>
                </div>
                <div v-else-if="$route.meta.showGetStartedButton">
                  <RouterLink :to="`/signin?redirectOnSuccess=${$route.meta.skipIfAuth}`">
                  <!--<RouterLink to='/signin'>-->
                    <button class="btn btn-info text-white">Get started!</button>
                  </RouterLink>
                  <div class="z-50 fixed top-2 right-2">
                      <RouterLink :to="`/signin?redirectOnSuccess=${$route.meta.skipIfAuth}`">
                          <button class="btn btn-info text-white">Get started!</button>
                      </RouterLink>
                  </div>
                </div>
            </div>
        </div>

        <!-- Sidebar -->
        <div v-if="user && $route.meta.showNavSidebar" data-theme="business">
          <div class="drawer lg:drawer-open">
            <input id="my-drawer" type="checkbox" class="drawer-toggle" v-model="isDrawerOpen" />
            <div class="drawer-content flex flex-col" :style="{ paddingTop: navbarHeight  + 'px' }"  data-theme="light">
              <div class="p-2">
                <!-- Page content here -->
                <RouterView />
              </div>
            </div>
            <div class="drawer-side z-500" :style="{ paddingTop: navbarHeight  + 'px' }">
              <label for="my-drawer" class="drawer-overlay"></label>
              <ul class="menu pt-10 p-4 w-50 min-h-full bg-base-200 text-base-content flex flex-col">
                <!-- Sidebar content here -->
                <div class="p-2.5 mt-3 flex items-center rounded-md px-4 text-white">
                  <i class="bi bi-blockquote-right text-2xl"></i>
                  <span class="text-[15px] ml-4 text-gray-200 font-bold"><RouterLink class="nav-link" to="/AIBatchProcess" v-on:click="isDrawerOpen = !isDrawerOpen">Process a file</RouterLink></span>
                </div>
                <div class="p-2.5 mt-3 flex items-center rounded-md px-4 text-white">
                  <i class="bi bi-clock-history text-2xl"></i>
                  <span class="text-[15px] ml-4 text-gray-200 font-bold"><RouterLink class="nav-link" to="/AIBatchProcessHistory" v-on:click="isDrawerOpen = !isDrawerOpen">History</RouterLink></span>
                </div>
                <div class="p-2.5 mt-3 flex items-center rounded-md px-4 text-white">
                  <i class="bi bi-question-circle text-2xl"></i>
                  <span class="text-[15px] ml-4 text-gray-200 font-bold"><RouterLink class="nav-link" to="/AIBatchProcessHelp" v-on:click="isDrawerOpen = !isDrawerOpen">Help</RouterLink></span>
                </div>
                <div class="p-2.5 mt-3 flex items-center rounded-md px-4 text-white">
                  <i class="bi bi-table text-2xl"></i>
                  <span class="text-[15px] ml-4 text-gray-200 font-bold"><a href="/AIBatchProcessExcelAddinLandingWeb" v-on:click="isDrawerOpen = !isDrawerOpen">Try our Excel Add-in!</a></span>
                </div>
                <div class="flex-grow"></div>
                <div class="p-2.5 mt-3 flex items-center rounded-md px-4 text-white">
                  <span class="text-[15px] ml-4 text-gray-200 text-center" v-html="currentPlanStatus"></span>
                </div>
              </ul>  
            </div>
          </div>           
        </div>
        <div v-else>
            <div :style="{ paddingTop: navbarHeight  + 'px' }" data-theme="light">
                <RouterView />
            </div>
        </div>
      </div>
    </container>
  </div>
</template>

<style scoped>
[v-cloak] {
   display: none;
}
</style>


<script>
import { firebaseAuth, db } from '@/firebaseInit';
import { collection, updateDoc, doc, getDoc, getDocs, setDoc, query, where } from "firebase/firestore";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { CST } from "@/Constants"


export default {
  data() {
    return {
      user: null,
      userDisplayName: '',
      navbarHeight: 0, // Initialize navbar height,
      isViewLoaded: false,
      isDrawerOpen: false
    };
  },
  computed: {
   sidebarHeight(){
      // Calculate the main content height dynamically
      return `calc(100vh - ${this.navbarHeight}px)`;
    },
    currentPlanStatus(){
      switch (this.$store.state.userCurrentPlan) {
        case CST.PLAN_PAYASYOUGO:
          return `Credits: ${this.$store.state.userCredits.toFixed(0)}`;

        case CST.PLAN_SUBSCRIPTION:
          return `Use your own API Key`

        case CST.PLAN_TRIAL:
        if (this.$store.state.isOfficeAddin) {        
          return `Trial plan - Credits: ${this.$store.state.userCredits.toFixed(0)}`
        }else{
          return `Trial plan<br>Credits: ${this.$store.state.userCredits.toFixed(0)}`
        }

        default:
          return ""
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustMainContentHeight);
  },
  created() {
    onAuthStateChanged(firebaseAuth, (user) => {
      this.user = user;
      this.$store.commit('setUser', user);
      if (this.user){
        this.userDisplayName = this.user.displayName
      }
      this.setUserProfileVuexVariables();
    })
    this.setUserProfileVuexVariables();
  },
  mounted() {
    this.$router.isReady().then(() => {
      if (this.$route.meta.isOfficeAddin || this.$route.meta.bothWebAndOfficeAddin){
        if (this.$store.state.isOfficeJSLoaded){
          this.$print.log("Office JS already loaded, info = " + JSON.stringify(window.Office.info))
          this.$store.commit('setIsOfficeAddin', true);
        }else{
          this.loadOfficeJs()
          .then((info) => {
            this.$print.log("Office JS plugin loaded.")
            if (info["host"] != null){
              this.$store.commit('setIsOfficeAddin', true); // We confirmed that the page was opened in an Office environment
            }
            this.$store.commit('setIsOfficeJSLoaded', true);
          })
          .catch(error => this.$print.error("Failed to load Office.js:", error));
        }
      }
      if (!this.$store.state.isOfficeAddin) {        
        this.$nextTick(() => {
          this.adjustMainContentHeight();
          window.addEventListener('resize', this.adjustMainContentHeight);
        });
      }
    });
  },
  methods: {
    async signout() {
      try {
        await signOut(firebaseAuth);
        
        if (this.$route.meta.requiresAuth) {
          if (this.$store.state.isOfficeAddin) {
            window.location.href = `${window.location.origin}${this.$route.meta.requiresAuth}`;

          } else {
            window.location.href = `${window.location.origin}${this.$route.meta.requiresAuth}`;
           
          }
        } else {
          window.location.href = `${window.location.origin}/AIBatchProcessLanding`; // => replace with main AIfficientools landing page

        }
      } catch (error) {
        this.$print.log("Error while trying to sign out: " + error);
      }
    }
    ,
    adjustMainContentHeight() {
      if (!this.$store.state.isOfficeAddin) {
        this.$nextTick(() => { // Ensure the DOM is updated
          this.navbarHeight = this.$refs.navbar.offsetHeight;
        })
      }
    },    
    closeDropdownOnClick(){
      const elem = document.activeElement;
      if (elem) {
        elem.blur();
      }
    },
    async setUserProfileVuexVariables(){
      if (this.user){
        const userProfileRef = doc(db, CST.COLLECTION_USER_PROFILE, this.$store.state.user.uid);
        var userProfileDoc = await getDoc(userProfileRef);

        // Check if the user exists
        if (!userProfileDoc.exists(userProfileRef)) {
          // User doesn't exist, treated as new User, assign default values to userProfile
          await this.createNewUserProfile(userProfileRef);
          userProfileDoc = await getDoc(userProfileRef);
        } 
        const userProfileData = userProfileDoc.data();     

        this.$store.commit('setUserCredits', userProfileData?.[CST.COLLECTION_USER_PROFILE_CREDITS]);
        this.$store.commit('setIsSubscriptionActive', userProfileData?.[CST.COLLECTION_USER_PROFILE_IS_SUBSCRIPTION_ACTIVE]);
        this.$store.commit('setUserAPIKeyLastFourDigits', userProfileData?.[CST.COLLECTION_USER_PROFILE_SUBSCRIPTION_OPEN_AI_API_KEY_LAST_FOUR_DIGITS])

        // Check that user subcription is still active
        this.checkUserSubscriptionActiveCurrent(userProfileRef, userProfileData)

        // If credits > trial credits and current plan is trial, then convert current plan to pay as you go
        let currentPlan = userProfileData?.[CST.COLLECTION_USER_PROFILE_CURRENT_PLAN];
        const userCredits = userProfileData?.[CST.COLLECTION_USER_PROFILE_CREDITS];
        if (currentPlan == CST.PLAN_TRIAL && userCredits > CST.PLAN_TRIAL_FREE_CREDITS){ 
          // Update the current plan value in firestore
          const userProfileUpdatedData = {
                [CST.COLLECTION_USER_PROFILE_CURRENT_PLAN]: CST.PLAN_PAYASYOUGO,
            };
          await updateDoc(userProfileRef, userProfileUpdatedData);
          currentPlan = CST.PLAN_PAYASYOUGO;
        }
        this.$store.commit('setUserCurrentPlan', currentPlan);
      }
    },
    async createNewUserProfile(userProfileRef){
      const defaultValues = {
            [CST.COLLECTION_USER_PROFILE_CURRENT_PLAN]: CST.PLAN_TRIAL,
            [CST.COLLECTION_USER_PROFILE_CREDITS]: CST.PLAN_TRIAL_FREE_CREDITS,
            [CST.COLLECTION_USER_PROFILE_IS_SUBSCRIPTION_ACTIVE]: false,
            [CST.COLLECTION_USER_PROFILE_SUBSCRIPTION_RECURRENCE]: '',
            [CST.COLLECTION_USER_PROFILE_SUBSCRIPTION_START_CURRENT_CYCLE]: '',
            [CST.COLLECTION_USER_PROFILE_SUBSCRIPTION_END_CURRENT_CYCLE]: '',
            [CST.COLLECTION_USER_PROFILE_SUBSCRIPTION_OPEN_AI_API_KEY]: '',
          };     
          
          await setDoc(userProfileRef, defaultValues);
    },
    async checkUserSubscriptionActiveCurrent(userProfileRef, subscription_end_current_cycle){
      // Check that user subcription is still active
      const today = new Date();
      const threeDaysFromNow = new Date(today.getTime() + (3 * 24 * 60 * 60 * 1000)); // Add 3 days to today
      const subscriptionEnd = new Date(subscription_end_current_cycle * 1000); // Convert Unix timestamp to JavaScript Date object

      // Compare the subscription end date to three days from now
      if (subscriptionEnd <= threeDaysFromNow) {
        // Update the document in Firestore
        await updateDoc(userProfileRef, {
          [CST.COLLECTION_USER_PROFILE_IS_SUBSCRIPTION_ACTIVE]: false
        });

        // Commit to Vuex store
        this.$store.commit('setIsSubscriptionActive', false);
      }
    },    
    openAifficientoolsLink(){
      Office.context.ui.openBrowserWindow("https://aifficientools.com");
    },
    openWebVersion(){
      Office.context.ui.openBrowserWindow("https://aifficientools.com/AIBatchProcess");
    },    
    loadOfficeJs() {
      return new Promise((resolve, reject) => {
          if (window.Office) {
              Office.onReady().then(resolve).catch(reject);
          } else {
              const script = document.createElement('script');
              script.src = "https://appsforoffice.microsoft.com/lib/1/hosted/office.js";
              document.head.appendChild(script);
              script.onload = () => {
                  Office.onReady().then((info) => {
                  resolve(info);
                  }).catch(reject);
              };
              script.onerror = () => {
                  this.$print.error("Failed to load Office.js script.");
                  reject(new Error("Failed to load the Office.js script"));
              };
          }
      });
    },    
  }
};
</script>
<style scoped>
</style>