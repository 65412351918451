import { createRouter, createWebHistory } from 'vue-router'
import { firebaseAuth } from '@/firebaseInit';
import { onAuthStateChanged } from "firebase/auth";


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('../views/Home.vue'),
      meta: { bothWebAndOfficeAddin: true,   title: 'Home', requiresAuth: false }
    },
    {
      path: '/blog',
      name: 'Blog',
      component: () => import('../views/Blog.vue'),
      meta: { requiresAuth: false }
    },
    {
      path: '/blogArticle-1',
      name: 'Blog - Article 1',
      component: () => import('../views/BlogArticle-1.vue'),
      meta: { requiresAuth: false }
    },
    {
      path: '/signin',
      name: 'signin',
      component: () => import('../views/Signin.vue'),
      meta: { requiresAuth: false, skipIfAuth: '/' },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('../views/Profile.vue'),
      meta: { requiresAuth: '/' },
    },
    {
      path: '/manageAIfficientoolsKey',
      name: 'ManageAIfficientoolsKey',
      component: () => import('../views/ManageAIfficientoolsKey.vue'),
      meta: { requiresAuth: '/', },
    },
    {
      path: '/showAIfficientoolsKey',
      name: 'ShowAIfficientoolsKey',
      component: () => import('../views/ShowAIfficientoolsKey.vue'),
      meta: {  requiresAuth: '/', },
    },
    {
      path: '/purchaseCredits',
      name: 'purchaseCredits',
      component: () => import('../views/PurchaseCredits.vue'),
      meta: { bothWebAndOfficeAddin: true, requiresAuth: '/' },
    },
    {
      path: '/selectPlan',
      name: 'selectPlan',
      component: () => import('../views/SelectPlan.vue'),
      meta: { bothWebAndOfficeAddin: true, requiresAuth: '/'},
    },
    {
      path: '/PurchaseCreditsSuccess',
      name: 'PurchaseCreditssuccess',
      component: () => import('../views/PurchaseCreditsSuccess.vue'),
      meta: { bothWebAndOfficeAddin: true, },// requiresAuth: '/' },
    },
    {
      path: '/subscriptionSuccess',
      name: 'subscriptionSuccess',
      component: () => import('../views/SubscriptionSuccess.vue'),
      meta: { bothWebAndOfficeAddin: true, requiresAuth: '/', },
    },
    {
      path: '/AIBatchProcessLanding',
      name: 'AIBatchProcessLanding',
      component: () => import('../views/AIBatchProcess/AIBatchProcessLanding.vue'),
      meta: { requiresAuth: false, skipIfAuth: '/AIBatchProcess', showGetStartedButton: true },
    },
    {
      path: '/AIBatchProcess',
      name: 'AIBatchProcess',
      component: () => import('../views/AIBatchProcess/AIBatchProcess.vue'),
      meta: { requiresAuth: '/AIBatchProcessLanding', showNavSidebar: true},
    },
    {
      path: '/AIBatchProcessHistory',
      name: 'AIBatchProcessHistory',
      component: () => import('../views/AIBatchProcess/AIBatchProcessHistory.vue'),
      meta: { requiresAuth: '/AIBatchProcessLanding', showNavSidebar: true },
    },
    {
      path: '/AIBatchProcessHelp',
      name: 'AIBatchProcessHelp',
      component: () => import('../views/AIBatchProcess/AIBatchProcessHelp.vue'),
      meta: { requiresAuth: '/AIBatchProcessLanding', showNavSidebar: true },
    },
    {
      path: '/signinOfficeAddin',
      name: 'signinOfficeAddin',
      component: () => import('../views/SigninOfficeAddin.vue'),
      meta: { bothWebAndOfficeAddin: true, requiresAuth: false },
    },
    {
      path: '/signinOfficeAddinSuccess',
      name: 'signinOfficeAddinSuccess',
      component: () => import('../views/SigninOfficeAddinSuccess.vue'),
      meta: { requiresAuth: '/' },
    },
    {
      path: '/AIBatchProcessExcelAddin',
      name: 'AIBatchProcessExcelAddin',
      component: () => import('../views/AIBatchProcess/AIBatchProcessExcelAddin.vue'),
      meta: { isOfficeAddin: true, requiresAuth: '/AIBatchProcessExcelAddinLanding', },
    },
    {
      path: '/AIBatchProcessExcelAddinHelp',
      name: 'AIBatchProcessExcelAddinHelp',
      component: () => import('../views/AIBatchProcess/AIBatchProcessExcelAddinHelp.vue'),
      meta: {isOfficeAddin: true, requiresAuth: '/AIBatchProcessExcelAddinLanding' },
    },  
    {
      path: '/AIBatchProcessExcelAddinManageAccount',
      name: 'AIBatchProcessExcelAddinManageAccount',
      component: () => import('../views/AIBatchProcess/AIBatchProcessExcelAddinManageAccount.vue'),
      meta: {isOfficeAddin: true, requiresAuth: '/AIBatchProcessExcelAddinLanding' },
    },    
    {
      path: '/AIBatchProcessExcelAddinLanding',
      name: 'AIBatchProcessExcelAddinLanding',
      component: () => import('../views/AIBatchProcess/AIBatchProcessExcelAddinLanding.vue'),
      meta: { isOfficeAddin: true, requiresAuth: false, skipIfAuth: '/AIBatchProcessExcelAddin' },
    },
    {
      path: '/AIBatchProcessExcelAddinLandingWeb',
      name: 'AIBatchProcessExcelAddinLandingWeb',
      component: () => import('../views/AIBatchProcess/AIBatchProcessExcelAddinLandingWeb.vue'),
      meta: { requiresAuth: false },
    },
    {
      path: '/:pathMatch(.*)*', // Catch-all route for 404 Page Not Found
      redirect: '/',
    },
    {
      path: '/help',
      name: 'Help',
      component: () => import('../views/Help.vue'),
      meta: { bothWebAndOfficeAddin: true,   title: 'Help', requiresAuth: false }
    },
    {
      path: '/termsOfService',
      name: 'TermsOfService',
      component: () => import('../views/TermsOfService.vue'),
      meta: { bothWebAndOfficeAddin: true,  title: 'Terms of Service', requiresAuth: false }
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: () => import('../views/Privacy.vue'),
      meta: { bothWebAndOfficeAddin: true,   title: 'Privacy', requiresAuth: false }
    },
    {
      path: '/getOpenAIAPIKeyHelp',
      name: 'getOpenAIAPIKeyHelp',
      component: () => import('../views/GetOpenAIAPIKeyHelp.vue'),
      meta: { bothWebAndOfficeAddin: true, requiresAuth: '/', },
    },
  ]
})

function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}


router.beforeEach(async (to, from, next) => {
  /*//const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  //const skipIfAuth = to.matched.some(record => record.meta.skipIfAuth);
  const requiresAuth = to.matched.find(record => record.meta.requiresAuth)?.meta.requiresAuth;
  const skipIfAuth = to.matched.find(record => record.meta.skipIfAuth)?.meta.skipIfAuth;
  const isAuthenticated = await getCurrentUser();

  if (requiresAuth && !isAuthenticated) {
    //next('/AIBatchProcessLanding');
    next(requiresAuth)
  } else if (skipIfAuth && isAuthenticated) {
    //next('/AIBatchProcess');
    next(skipIfAuth);    
  } else {  
    next();
  }*/


  // Check if the route requires authentication or should skip if authenticated
  const requiresAuth = to.matched.find(record => record.meta.requiresAuth)?.meta.requiresAuth;
  const skipIfAuth = to.matched.find(record => record.meta.skipIfAuth)?.meta.skipIfAuth;
  const isAuthenticated = await getCurrentUser();

  // Get redirect URL from query parameter (if available)
  const redirectOnSuccess = to.query.redirectOnSuccess;

  if (requiresAuth && !isAuthenticated) {
    // User is not authenticated and route requires authentication
    next(requiresAuth);
  } else if (skipIfAuth && isAuthenticated) {
    // User is authenticated and route should skip if authenticated

    // Redirect to `redirectOnSuccess` if it's present, otherwise use the default
    if (redirectOnSuccess) {
      next(redirectOnSuccess);
    } else {
      next(skipIfAuth);
    }
  } else {
    next();
  }

});

export default router
